.gender-doodle {
    display: none;
}
@media (min-width: 768px) {
    .gender-doodle {
        display: block;
        width: 100px;
    }
    .gender-doodle svg {
        width: 100%;
        height: auto;
    }
}
