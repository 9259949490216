@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;700&display=swap');

:root {
    font-size: 62.5%;
    --bettear-primary-color: #4357e7;
    --bettear-primary-color-lighter: #4357e7d1;
    --bettear-secondary-color: #516371;
    --bettear-title-color: #0c0b26;
    --bettear-border-color: #e0e1e5;
    --bettear-body-font-size: 1.8rem;
    --bettear-dark-color: #0c0b26;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}
body {
    margin: 0;
    font-family: 'Heebo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.3;
    color: var(--bettear-secondary-color);
    font-size: var(--bettear-body-font-size);
}

main {
    display: block;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
    font-weight: 700;
    font-family: 'Heebo';
    text-align: center;
    color: var(--bettear-title-color);
}

h1,
.h1 {
    font-size: calc(var(--bettear-body-font-size) * 1.25);
    text-align: center;
}
@media (min-width: 768px) {
    h1,
    .h1 {
        font-size: calc(var(--bettear-body-font-size) * 1.55);
        text-align: center;
    }
}
h2,
.h2 {
    font-size: calc(var(--bettear-body-font-size) * 1.15);
}
button,
.btn {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Heebo';
    font-weight: 400;
    border: none;
    background-color: #e0e1e5;
    font-size: var(--bettear-body-font-size);
    padding: 1.5rem;
}
button:hover {
    cursor: pointer;
}
button:disabled {
    background-color: var(--bettear-secondary-color);
}
button:disabled:hover {
    cursor: not-allowed;
}
.btn-default {
    background-color: var(--bettear-primary-color);
    color: #fff;
}

ul {
    list-style: none; /* Remove default bullets */
    padding: 0;
}
ul li {
    position: relative;
    padding-right: 3rem;
    margin-bottom: 1.5rem;
}
ul li::before {
    background-color: var(--bettear-primary-color);
    position: absolute;
    width: 2rem;
    height: 2rem;
    content: '';
    right: 0.5rem;
    top: 0;
    border-radius: 50%;
}
ol {
    counter-reset: my-awesome-counter;
    list-style: none;
    padding: 0;
}
ol li {
    counter-increment: my-awesome-counter;
    position: relative;
    padding-inline-start: 5rem;
    min-height: 4.5rem;
    margin-bottom: 1rem;
}
ol li::before {
    content: counter(my-awesome-counter);
    color: var(--bettear-primary-color);
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    --size: 3.5rem;
    right: 0;
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    /* top: 0; */
    background: #fff;
    border-radius: 50%;
    text-align: center;
    border: 2px solid var(--bettear-primary-color);
    display: inline-block;
    margin-inline-end: 0.5em;
}
