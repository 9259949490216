.screen-returning-user {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.screen-returning-user .header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 2rem 3.5rem;
}
.screen-returning-user .inner {
    min-height: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
