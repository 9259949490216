.audio-slider {
    width: 100%;
    margin-top: 3rem;
}
.audio-slider .labels {
    display: flex;
    justify-content: space-between;
}
.audio-actions {
    margin-top: 2rem;
}
.audio-actions button {
    padding: 0;
    background-color: transparent;
    margin: 0 auto;
}
.audio-actions button:disabled {
    opacity: 0.8;
}
input[type='range'] {
    width: 100%;
}
input[type='range'] {
    width: 100%;
    margin: 3px 0;
    background-color: transparent;
    -webkit-appearance: none;
}
input[type='range']:focus {
    outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
    background: #e0e1e5;
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 24px;
    cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
    margin-top: -3px;
    width: 30px;
    height: 30px;
    background: #4357e7;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 30px;
    cursor: pointer;
    -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
    background: #e3e4e7;
}
input[type='range']::-moz-range-track {
    background: #e0e1e5;
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 24px;
    cursor: pointer;
}
input[type='range']::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: #4357e7;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 30px;
    cursor: pointer;
}
input[type='range']::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 4px 0;
    color: transparent;
    width: 100%;
    height: 24px;
    cursor: pointer;
}
input[type='range']::-ms-fill-lower {
    background: #dddee3;
    border: 0;
    border-radius: 50px;
}
input[type='range']::-ms-fill-upper {
    background: #e0e1e5;
    border: 0;
    border-radius: 50px;
}
input[type='range']::-ms-thumb {
    width: 30px;
    height: 30px;
    background: #4357e7;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
    background: #e0e1e5;
}
input[type='range']:focus::-ms-fill-upper {
    background: #e3e4e7;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type='range'] {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
}
