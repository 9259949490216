.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    /* transform: translateX(50%); */
    width: 100%;
    padding: 1rem 3.5rem;
    background-color: #ffffff;
    z-index: 9;
}
.footer .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.footer .btn {
    flex: 0 1 auto;
    /* width: max-content; */
    padding: 1.5rem 2.5rem;
    /* min-width: 170px; */
    width: 100%;
    max-width: 350px;
}
.footer button {
    transition: all 0.4 ease-in-out;
}

.footer button:disabled {
    color: #ffffff;
    background-color: #828282;
}
@media (min-width: 450px) {
    /* .footer .container {
        flex-direction: row;
    } */
    .footer .btn {
        /* min-width: 170px;
        width: auto; */
    }
}
