p.progress-text {
    text-align: center;
    color: var(--bettear-primary-color);
}
p.progress-text span:not(:first-of-type) {
    border-inline-start: 1px solid;
    margin-inline-start: 0.5em;
    padding-inline-start: 0.5em;
    display: inline-block;
}
