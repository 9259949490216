.counter {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 4rem;
    text-align: center;
}
.counter .time {
    background-color: #0c0b26;
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    display: block;
    width: 7rem;
    height: 7rem;
    line-height: 7.5rem;
    text-align: center;
    padding: 0;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}

.btn-break {
    background-color: #ffffff;
    color: var(--bettear-primary-color);
    border: 1px solid var(--bettear-primary-color);
    /* font-size: 1.6rem;
    padding: 0.6rem 1.5rem; */
}
.btn-break .icon {
    margin-inline-end: 1rem;
}
