@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;700&display=swap);
:root {
    font-size: 62.5%;
    --bettear-primary-color: #4357e7;
    --bettear-primary-color-lighter: #4357e7d1;
    --bettear-secondary-color: #516371;
    --bettear-title-color: #0c0b26;
    --bettear-border-color: #e0e1e5;
    --bettear-body-font-size: 1.8rem;
    --bettear-dark-color: #0c0b26;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}
body {
    margin: 0;
    font-family: 'Heebo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.3;
    color: #516371;
    color: var(--bettear-secondary-color);
    font-size: 1.8rem;
    font-size: var(--bettear-body-font-size);
}

main {
    display: block;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
    font-weight: 700;
    font-family: 'Heebo';
    text-align: center;
    color: #0c0b26;
    color: var(--bettear-title-color);
}

h1,
.h1 {
    font-size: calc(1.8rem * 1.25);
    font-size: calc(var(--bettear-body-font-size) * 1.25);
    text-align: center;
}
@media (min-width: 768px) {
    h1,
    .h1 {
        font-size: calc(1.8rem * 1.55);
        font-size: calc(var(--bettear-body-font-size) * 1.55);
        text-align: center;
    }
}
h2,
.h2 {
    font-size: calc(1.8rem * 1.15);
    font-size: calc(var(--bettear-body-font-size) * 1.15);
}
button,
.btn {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Heebo';
    font-weight: 400;
    border: none;
    background-color: #e0e1e5;
    font-size: 1.8rem;
    font-size: var(--bettear-body-font-size);
    padding: 1.5rem;
}
button:hover {
    cursor: pointer;
}
button:disabled {
    background-color: #516371;
    background-color: var(--bettear-secondary-color);
}
button:disabled:hover {
    cursor: not-allowed;
}
.btn-default {
    background-color: #4357e7;
    background-color: var(--bettear-primary-color);
    color: #fff;
}

ul {
    list-style: none; /* Remove default bullets */
    padding: 0;
}
ul li {
    position: relative;
    padding-right: 3rem;
    margin-bottom: 1.5rem;
}
ul li::before {
    background-color: #4357e7;
    background-color: var(--bettear-primary-color);
    position: absolute;
    width: 2rem;
    height: 2rem;
    content: '';
    right: 0.5rem;
    top: 0;
    border-radius: 50%;
}
ol {
    counter-reset: my-awesome-counter;
    list-style: none;
    padding: 0;
}
ol li {
    counter-increment: my-awesome-counter;
    position: relative;
    -webkit-padding-start: 5rem;
            padding-inline-start: 5rem;
    min-height: 4.5rem;
    margin-bottom: 1rem;
}
ol li::before {
    content: counter(my-awesome-counter);
    color: #4357e7;
    color: var(--bettear-primary-color);
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    --size: 3.5rem;
    right: 0;
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    /* top: 0; */
    background: #fff;
    border-radius: 50%;
    text-align: center;
    border: 2px solid #4357e7;
    border: 2px solid var(--bettear-primary-color);
    display: inline-block;
    -webkit-margin-end: 0.5em;
            margin-inline-end: 0.5em;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    align-self: flex-end;
}
.header .btn-break {
    background-color: #ffffff;
    color: var(--bettear-primary-color);
    border: 1px solid var(--bettear-primary-color);
    font-size: 1.6rem;
    padding: 0.6rem 1.5rem;
}
.header .btn-break .icon {
    -webkit-margin-start: 1.2rem;
            margin-inline-start: 1.2rem;
}

.progress {
    display: block;
    width: 100%;
    height: 15px;
    background-color: #f1f1f1;
    border-radius: 30px;
}
.progress .progress-bar {
    height: 100%;
    background-color: var(--bettear-primary-color);
    border-radius: 30px;
}

p.progress-text {
    text-align: center;
    color: var(--bettear-primary-color);
}
p.progress-text span:not(:first-of-type) {
    -webkit-border-start: 1px solid;
            border-inline-start: 1px solid;
    -webkit-margin-start: 0.5em;
            margin-inline-start: 0.5em;
    -webkit-padding-start: 0.5em;
            padding-inline-start: 0.5em;
    display: inline-block;
}

#a11y-panel {
    position: fixed;
    background-color: #f5f9fc;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #0c0b26;
    z-index: 99;
    top: 122px;
    --a11y-panel-size: 130px;
    left: calc(0px - var(--a11y-panel-size));
    width: var(--a11y-panel-size);
    transition: all 0.3s ease-in-out;
    padding: 0 1rem;
}
#a11y-panel[aria-expanded='true'] {
    left: 0;
}
#a11y-panel #a11y-toggler {
    position: absolute;
    right: -30px;
    padding: 0;
    background-color: transparent;
}
#a11y-panel .a11y-action {
    background-color: transparent;
    padding: 0;
    border: none;
    font-size: 1.4rem;
    font-family: 'Arial', Helvetica, sans-serif;
    display: flex;
    align-items: center;
    margin: 1rem 0;
}
#a11y-panel .a11y-action .icon {
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-margin-end: 0.5rem;
            margin-inline-end: 0.5rem;
}
#a11y-panel hr {
    border-top: 1px solid #dfdfdf;
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    /* transform: translateX(50%); */
    width: 100%;
    padding: 1rem 3.5rem;
    background-color: #ffffff;
    z-index: 9;
}
.footer .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
.footer .btn {
    flex: 0 1 auto;
    /* width: max-content; */
    padding: 1.5rem 2.5rem;
    /* min-width: 170px; */
    width: 100%;
    max-width: 350px;
}
.footer button {
    transition: all 0.4 ease-in-out;
}

.footer button:disabled {
    color: #ffffff;
    background-color: #828282;
}
@media (min-width: 450px) {
    /* .footer .container {
        flex-direction: row;
    } */
    .footer .btn {
        /* min-width: 170px;
        width: auto; */
    }
}

.exit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 500px;
    margin: auto;
    min-height: calc(100% - 150px);
}
.exit form {
    width: 100%;
    max-width: 450px;
}
.exit form input {
    width: 100%;
    height: 5.5rem;
    font-size: 2rem;
    padding: 0.5em;
    background-color: #f5f9fc;
    border: 1px solid #e0e1e5;
    color: #8c8a8a;
    margin-bottom: 1.5rem;
}

.exit form input::-webkit-input-placeholder {
    color: #000000;
}

.exit form input:-ms-input-placeholder {
    color: #000000;
}

.exit form input::-ms-input-placeholder {
    color: #000000;
}

.exit form input::placeholder {
    color: #000000;
}
.exit form button {
    width: 100%;
}
.exit form .err-msg {
    font-size: 1.8rem;
    margin: 0 0 1.5rem;
    color: #8e0c0c;
}

.age-wrap {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 342px;
    position: relative;
}
#age-toggler {
    display: block;
    width: 100%;
    background-color: #fff;
    height: 6.5rem;
    text-align: right;
    border: 1px solid var(--bettear-border-color);
    border-radius: 3px;
    font-size: calc(var(--bettear-body-font-size) * 1.15);
    color: var(--bettear-secondary-color);
    line-height: 1;
}
#age-dropdown {
    display: none;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;
    border: 1px solid var(--bettear-border-color);
    position: absolute;
    background-color: #fff;
}
#age-dropdown.show {
    display: block;
}
#age-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
}
#age-dropdown ul li {
    width: 100%;
    padding: 1rem 2rem;
    margin: 0;
}
#age-dropdown ul li:hover,
#age-dropdown ul li.active {
    background-color: var(--bettear-primary-color);
    color: #fff;
    cursor: pointer;
}
#age-dropdown ul li::before {
    content: none;
}
.gender-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    max-width: 342px;
    margin: 0 auto;
    grid-gap: 2rem;
    gap: 2rem;
}
.gender-wrap .gender {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--bettear-border-color);
    border-radius: 4px;
    background-color: transparent;
    font-weight: 700;
}
.gender-wrap .gender:hover {
    cursor: pointer;
    box-shadow: 0 0 10px #71717140;
    border-color: var(--bettear-primary-color);
}
button.gender.active {
    border-color: var(--bettear-primary-color);
    color: var(--bettear-primary-color);
}
button.gender.active svg path {
    fill: var(--bettear-primary-color);
}
.gender-wrap .gender span.icon {
    display: flex;
    width: 75px;
    height: 100px;
    align-items: center;
    justify-content: center;
}

.gender-doodle {
    display: none;
}
@media (min-width: 768px) {
    .gender-doodle {
        display: block;
        width: 100px;
    }
    .gender-doodle svg {
        width: 100%;
        height: auto;
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.rotating {
    animation: rotating 3.5s linear infinite;
}

.audio-actions button {
    height: 60px;
}
.audio-actions button svg {
    display: block;
}

.slider-controller {
    width: 40px;
    height: 40px;
    padding: 0;
}

.audio-slider {
    width: 100%;
    margin-top: 3rem;
}
.audio-slider .labels {
    display: flex;
    justify-content: space-between;
}
.audio-actions {
    margin-top: 2rem;
}
.audio-actions button {
    padding: 0;
    background-color: transparent;
    margin: 0 auto;
}
.audio-actions button:disabled {
    opacity: 0.8;
}
input[type='range'] {
    width: 100%;
}
input[type='range'] {
    width: 100%;
    margin: 3px 0;
    background-color: transparent;
    -webkit-appearance: none;
}
input[type='range']:focus {
    outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
    background: #e0e1e5;
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 24px;
    cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
    margin-top: -3px;
    width: 30px;
    height: 30px;
    background: #4357e7;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 30px;
    cursor: pointer;
    -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
    background: #e3e4e7;
}
input[type='range']::-moz-range-track {
    background: #e0e1e5;
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 24px;
    cursor: pointer;
}
input[type='range']::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: #4357e7;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 30px;
    cursor: pointer;
}
input[type='range']::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 4px 0;
    color: transparent;
    width: 100%;
    height: 24px;
    cursor: pointer;
}
input[type='range']::-ms-fill-lower {
    background: #dddee3;
    border: 0;
    border-radius: 50px;
}
input[type='range']::-ms-fill-upper {
    background: #e0e1e5;
    border: 0;
    border-radius: 50px;
}
input[type='range']::-ms-thumb {
    width: 30px;
    height: 30px;
    background: #4357e7;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
    background: #e0e1e5;
}
input[type='range']:focus::-ms-fill-upper {
    background: #e3e4e7;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type='range'] {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
}

.player-wrapper {
    background-color: #f3f3f3;
    padding: 1.5rem;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 650px;
    border: 1px solid #e0e1e5;
}
.player-wrapper .audio-actions {
    justify-content: center;
}

.screen-returning-user {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.screen-returning-user .header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 2rem 3.5rem;
}
.screen-returning-user .inner {
    min-height: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.counter {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 4rem;
    text-align: center;
}
.counter .time {
    background-color: #0c0b26;
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    display: block;
    width: 7rem;
    height: 7rem;
    line-height: 7.5rem;
    text-align: center;
    padding: 0;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}

.btn-break {
    background-color: #ffffff;
    color: var(--bettear-primary-color);
    border: 1px solid var(--bettear-primary-color);
    /* font-size: 1.6rem;
    padding: 0.6rem 1.5rem; */
}
.btn-break .icon {
    -webkit-margin-end: 1rem;
            margin-inline-end: 1rem;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--bettear-primary-color);
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.screen-end form {
    width: 100%;
    max-width: 450px;
}
.screen-end form input {
    width: 100%;
    height: 5.5rem;
    font-size: 2rem;
    padding: 0.5em;
    background-color: #f5f9fc;
    border: 1px solid #e0e1e5;
    color: #8c8a8a;
    margin-bottom: 1.5rem;
}

.screen-end form input::-webkit-input-placeholder {
    color: #000000;
}

.screen-end form input:-ms-input-placeholder {
    color: #000000;
}

.screen-end form input::-ms-input-placeholder {
    color: #000000;
}

.screen-end form input::placeholder {
    color: #000000;
}
.screen-end form button {
    width: 100%;
}
.screen-end form .err-msg {
    font-size: 1.8rem;
    margin: 0 0 1.5rem;
    color: #8e0c0c;
}

/* .loader {
    color: #ffffff;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
@-webkit-keyframes load4 {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
            0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
            0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
            2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
            -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}
@keyframes load4 {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
            0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
            0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
            2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
            -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
} */

*:focus {
    outline: none;
}
body[data-mode='dark'] {
    background-color: var(--bettear-dark-color);
    color: #f5f9fc;
}
body[data-mode='dark'] h1,
body[data-mode='dark'] h2,
body[data-mode='dark'] h3,
body[data-mode='dark'] h4,
body[data-mode='dark'] h5,
body[data-mode='dark'] .h1,
body[data-mode='dark'] .h2,
body[data-mode='dark'] .h3,
body[data-mode='dark'] .h4,
body[data-mode='dark'] .h5 {
    color: #f5f9fc;
}
body[data-mode='dark'] p.progress-text {
    text-align: center;
    color: #bebebe;
}
body[data-mode='dark'] #age-toggler {
    background-color: var(--bettear-dark-color);
    color: #f5f9fc;
    border-color: #f5f9fc;
}
body[data-mode='dark'] #age-dropdown {
    background-color: var(--bettear-dark-color);
}
body[data-mode='dark'] .gender-wrap .gender:not(.active) {
    color: #f5f9fc;
}
body[data-mode='dark'] .gender-wrap .gender.active {
    background-color: #f5f9fc;
}
body[data-mode='dark'] button.btn-selectable:not(.active),
body[data-mode='dark'] button.selectable:not(.active) {
    background-color: var(--bettear-dark-color);
    color: #f5f9fc;
    border-color: #f5f9fc;
}
body[data-mode='dark'] button.btn-selectable:not(.has-icon):not(.active):before,
body[data-mode='dark'] button.selectable:not(.has-icon):not(.active):before,
body[data-mode='dark'] button.btn-selectable.active,
body[data-mode='dark'] button.selectable.active {
    background-color: #f5f9fc;
}
body[data-mode='dark'] .audio-actions svg,
body[data-mode='dark'] .audio-actions svg circle {
    fill: #f5f9fc;
}
.audio-actions svg,
.audio-actions svg circle {
    fill: #516371;
}

body[data-mode='dark'] .audio-actions svg path {
    stroke: var(--bettear-dark-color);
}
body[data-mode='dark'] .counter .time {
    background-color: #f5f9fc;
    color: var(--bettear-dark-color);
}
body[data-mode='dark'] .gender-doodle {
    border-radius: 50%;
    background-color: #f5f9fc;
}
body[data-mode='dark'] .player-wrapper {
    background-color: var(--bettear-dark-color);
}
body[data-mode='dark'] .footer {
    background-color: var(--bettear-dark-color);
}
.App {
    max-width: 960px;
    margin: 0 auto;
    width: 100vw;
    padding: 0 3.5rem 15rem;
    position: relative;
    overflow: auto;
    min-height: 100vh;
}
/* @media (min-width: 450px) {
    .App {
        padding: 0 3.5rem 8rem;
    }
} */
.fixed-top {
    /* position: fixed; */
    top: 0;
    background-color: #ffffff;
    width: 100%;
    right: 0;
    padding: 0;
}
.screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: calc(100vh - 171px);
    height: 50px; */
}
/* .screen .content {
    height: 100%;
} */
.flex-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 3.5rem;
    gap: 3.5rem;
    justify-content: center;
}
.flex-wrap > div {
    flex: 1 1;
    max-width: 100%;
}

@media (min-width: 768px) {
    .flex-wrap {
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        grid-gap: 6.5rem;
        gap: 6.5rem;
        justify-content: center;
    }
    .flex-wrap > div {
        max-width: 60%;
    }
}
.screen .content .startpage h1 {
    text-align: inherit;
}
.d-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 3.5rem;
    gap: 3.5rem;
    justify-content: center;
    align-items: center;
}
@media (min-width: 768px) {
    .d-flex {
        display: flex;
        /* flex-wrap: wrap; */
        flex-direction: row;
        width: 100%;
        grid-gap: 6.5rem;
        gap: 6.5rem;
        justify-content: center;
    }
}

button.btn-selectable,
button.selectable {
    display: flex;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid var(--bettear-border-color);
    margin-bottom: 1rem;
    color: var(--bettear-secondary-color);
    justify-content: flex-start;
    text-align: right;
    font-size: calc(var(--bettear-body-font-size) * 0.89);
    padding: 1.5rem;
    font-weight: 700;
}
button.btn-selectable:hover,
button.selectable:hover {
    box-shadow: 0 0 10px #71717140;
    border-color: var(--bettear-primary-color);
}
button.btn-selectable:not(.has-icon):before,
button.selectable:not(.has-icon):before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    background-color: var(--bettear-secondary-color);
    border-radius: 100%;
    -webkit-margin-end: 1.5rem;
            margin-inline-end: 1.5rem;
    flex: 0 0 2rem;
}
button.btn-selectable.active,
button.selectable.active {
    border-color: var(--bettear-primary-color);
    color: var(--bettear-primary-color);
}
button.btn-selectable.active:not(.has-icon):before,
button.selectable.active:not(.has-icon):before {
    background-color: var(--bettear-primary-color);
}
button.btn-device {
    width: 100%;
    margin-bottom: 1.5rem;
    border-radius: 7px;
    border: 1px solid var(--bettear-border-color);
    background-color: #ffffff;
    justify-content: flex-start;
    padding: 1rem 3rem;
    font-size: 2rem;
    font-weight: 700;
    color: var(--bettear-secondary-color);
}
button.btn-selectable span.icon,
button.selectable span.icon {
    flex: 0 0 55px;
    margin: 0 1.5rem 0 2.5rem;
}

.audio-actions {
    display: flex;
}

.bold {
    font-weight: 700;
    color: #000;
}
button.btn-selectable.active .bold {
    color: #142392;
}
.intext-icon {
    height: 1.2em;
    display: inline-block;
    vertical-align: middle;
}
.intext-icon svg {
    height: 100%;
    width: auto;
}

