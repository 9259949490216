.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    align-self: flex-end;
}
.header .btn-break {
    background-color: #ffffff;
    color: var(--bettear-primary-color);
    border: 1px solid var(--bettear-primary-color);
    font-size: 1.6rem;
    padding: 0.6rem 1.5rem;
}
.header .btn-break .icon {
    margin-inline-start: 1.2rem;
}
