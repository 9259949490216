.age-wrap {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 342px;
    position: relative;
}
#age-toggler {
    display: block;
    width: 100%;
    background-color: #fff;
    height: 6.5rem;
    text-align: right;
    border: 1px solid var(--bettear-border-color);
    border-radius: 3px;
    font-size: calc(var(--bettear-body-font-size) * 1.15);
    color: var(--bettear-secondary-color);
    line-height: 1;
}
#age-dropdown {
    display: none;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;
    border: 1px solid var(--bettear-border-color);
    position: absolute;
    background-color: #fff;
}
#age-dropdown.show {
    display: block;
}
#age-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
}
#age-dropdown ul li {
    width: 100%;
    padding: 1rem 2rem;
    margin: 0;
}
#age-dropdown ul li:hover,
#age-dropdown ul li.active {
    background-color: var(--bettear-primary-color);
    color: #fff;
    cursor: pointer;
}
#age-dropdown ul li::before {
    content: none;
}
.gender-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    max-width: 342px;
    margin: 0 auto;
    gap: 2rem;
}
.gender-wrap .gender {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--bettear-border-color);
    border-radius: 4px;
    background-color: transparent;
    font-weight: 700;
}
.gender-wrap .gender:hover {
    cursor: pointer;
    box-shadow: 0 0 10px #71717140;
    border-color: var(--bettear-primary-color);
}
button.gender.active {
    border-color: var(--bettear-primary-color);
    color: var(--bettear-primary-color);
}
button.gender.active svg path {
    fill: var(--bettear-primary-color);
}
.gender-wrap .gender span.icon {
    display: flex;
    width: 75px;
    height: 100px;
    align-items: center;
    justify-content: center;
}
