#a11y-panel {
    position: fixed;
    background-color: #f5f9fc;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #0c0b26;
    z-index: 99;
    top: 122px;
    --a11y-panel-size: 130px;
    left: calc(0px - var(--a11y-panel-size));
    width: var(--a11y-panel-size);
    transition: all 0.3s ease-in-out;
    padding: 0 1rem;
}
#a11y-panel[aria-expanded='true'] {
    left: 0;
}
#a11y-panel #a11y-toggler {
    position: absolute;
    right: -30px;
    padding: 0;
    background-color: transparent;
}
#a11y-panel .a11y-action {
    background-color: transparent;
    padding: 0;
    border: none;
    font-size: 1.4rem;
    font-family: 'Arial', Helvetica, sans-serif;
    display: flex;
    align-items: center;
    margin: 1rem 0;
}
#a11y-panel .a11y-action .icon {
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 0.5rem;
}
#a11y-panel hr {
    border-top: 1px solid #dfdfdf;
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}
