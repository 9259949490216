.exit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 500px;
    margin: auto;
    min-height: calc(100% - 150px);
}
.exit form {
    width: 100%;
    max-width: 450px;
}
.exit form input {
    width: 100%;
    height: 5.5rem;
    font-size: 2rem;
    padding: 0.5em;
    background-color: #f5f9fc;
    border: 1px solid #e0e1e5;
    color: #8c8a8a;
    margin-bottom: 1.5rem;
}

.exit form input::placeholder {
    color: #000000;
}
.exit form button {
    width: 100%;
}
.exit form .err-msg {
    font-size: 1.8rem;
    margin: 0 0 1.5rem;
    color: #8e0c0c;
}
