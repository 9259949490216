*:focus {
    outline: none;
}
body[data-mode='dark'] {
    background-color: var(--bettear-dark-color);
    color: #f5f9fc;
}
body[data-mode='dark'] h1,
body[data-mode='dark'] h2,
body[data-mode='dark'] h3,
body[data-mode='dark'] h4,
body[data-mode='dark'] h5,
body[data-mode='dark'] .h1,
body[data-mode='dark'] .h2,
body[data-mode='dark'] .h3,
body[data-mode='dark'] .h4,
body[data-mode='dark'] .h5 {
    color: #f5f9fc;
}
body[data-mode='dark'] p.progress-text {
    text-align: center;
    color: #bebebe;
}
body[data-mode='dark'] #age-toggler {
    background-color: var(--bettear-dark-color);
    color: #f5f9fc;
    border-color: #f5f9fc;
}
body[data-mode='dark'] #age-dropdown {
    background-color: var(--bettear-dark-color);
}
body[data-mode='dark'] .gender-wrap .gender:not(.active) {
    color: #f5f9fc;
}
body[data-mode='dark'] .gender-wrap .gender.active {
    background-color: #f5f9fc;
}
body[data-mode='dark'] button.btn-selectable:not(.active),
body[data-mode='dark'] button.selectable:not(.active) {
    background-color: var(--bettear-dark-color);
    color: #f5f9fc;
    border-color: #f5f9fc;
}
body[data-mode='dark'] button.btn-selectable:not(.has-icon):not(.active):before,
body[data-mode='dark'] button.selectable:not(.has-icon):not(.active):before,
body[data-mode='dark'] button.btn-selectable.active,
body[data-mode='dark'] button.selectable.active {
    background-color: #f5f9fc;
}
body[data-mode='dark'] .audio-actions svg,
body[data-mode='dark'] .audio-actions svg circle {
    fill: #f5f9fc;
}
.audio-actions svg,
.audio-actions svg circle {
    fill: #516371;
}

body[data-mode='dark'] .audio-actions svg path {
    stroke: var(--bettear-dark-color);
}
body[data-mode='dark'] .counter .time {
    background-color: #f5f9fc;
    color: var(--bettear-dark-color);
}
body[data-mode='dark'] .gender-doodle {
    border-radius: 50%;
    background-color: #f5f9fc;
}
body[data-mode='dark'] .player-wrapper {
    background-color: var(--bettear-dark-color);
}
body[data-mode='dark'] .footer {
    background-color: var(--bettear-dark-color);
}
.App {
    max-width: 960px;
    margin: 0 auto;
    width: 100vw;
    padding: 0 3.5rem 15rem;
    position: relative;
    overflow: auto;
    min-height: 100vh;
}
/* @media (min-width: 450px) {
    .App {
        padding: 0 3.5rem 8rem;
    }
} */
.fixed-top {
    /* position: fixed; */
    top: 0;
    background-color: #ffffff;
    width: 100%;
    right: 0;
    padding: 0;
}
.screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: calc(100vh - 171px);
    height: 50px; */
}
/* .screen .content {
    height: 100%;
} */
.flex-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3.5rem;
    justify-content: center;
}
.flex-wrap > div {
    flex: 1;
    max-width: 100%;
}

@media (min-width: 768px) {
    .flex-wrap {
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        gap: 6.5rem;
        justify-content: center;
    }
    .flex-wrap > div {
        max-width: 60%;
    }
}
.screen .content .startpage h1 {
    text-align: inherit;
}
.d-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3.5rem;
    justify-content: center;
    align-items: center;
}
@media (min-width: 768px) {
    .d-flex {
        display: flex;
        /* flex-wrap: wrap; */
        flex-direction: row;
        width: 100%;
        gap: 6.5rem;
        justify-content: center;
    }
}

button.btn-selectable,
button.selectable {
    display: flex;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid var(--bettear-border-color);
    margin-bottom: 1rem;
    color: var(--bettear-secondary-color);
    justify-content: flex-start;
    text-align: right;
    font-size: calc(var(--bettear-body-font-size) * 0.89);
    padding: 1.5rem;
    font-weight: 700;
}
button.btn-selectable:hover,
button.selectable:hover {
    box-shadow: 0 0 10px #71717140;
    border-color: var(--bettear-primary-color);
}
button.btn-selectable:not(.has-icon):before,
button.selectable:not(.has-icon):before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    background-color: var(--bettear-secondary-color);
    border-radius: 100%;
    margin-inline-end: 1.5rem;
    flex: 0 0 2rem;
}
button.btn-selectable.active,
button.selectable.active {
    border-color: var(--bettear-primary-color);
    color: var(--bettear-primary-color);
}
button.btn-selectable.active:not(.has-icon):before,
button.selectable.active:not(.has-icon):before {
    background-color: var(--bettear-primary-color);
}
button.btn-device {
    width: 100%;
    margin-bottom: 1.5rem;
    border-radius: 7px;
    border: 1px solid var(--bettear-border-color);
    background-color: #ffffff;
    justify-content: flex-start;
    padding: 1rem 3rem;
    font-size: 2rem;
    font-weight: 700;
    color: var(--bettear-secondary-color);
}
button.btn-selectable span.icon,
button.selectable span.icon {
    flex: 0 0 55px;
    margin: 0 1.5rem 0 2.5rem;
}

.audio-actions {
    display: flex;
}

.bold {
    font-weight: 700;
    color: #000;
}
button.btn-selectable.active .bold {
    color: #142392;
}
.intext-icon {
    height: 1.2em;
    display: inline-block;
    vertical-align: middle;
}
.intext-icon svg {
    height: 100%;
    width: auto;
}
