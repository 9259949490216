.progress {
    display: block;
    width: 100%;
    height: 15px;
    background-color: #f1f1f1;
    border-radius: 30px;
}
.progress .progress-bar {
    height: 100%;
    background-color: var(--bettear-primary-color);
    border-radius: 30px;
}
