.player-wrapper {
    background-color: #f3f3f3;
    padding: 1.5rem;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 650px;
    border: 1px solid #e0e1e5;
}
.player-wrapper .audio-actions {
    justify-content: center;
}
